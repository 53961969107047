import React from "react";
import ReactDOM from "react-dom";
import { Button, Form } from "react-bootstrap";
import Flexbox from 'flexbox-react';
import nicknames from "./known_nicknames.js";
import { Link } from "react-router-dom";

export default class RSVP extends React.Component {

  constructor(props) {
    super(props);
    this.password = this.props.password;
    this.state = {
        firstName: "",
        lastName: "",
        guestList: [],
        group: [],
        attendance: {},
        noMatchFound: false,
        plusOneName: ""
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePlusOneNameChange = this.handlePlusOneNameChange.bind(this);
    this.highlightGuest = this.highlightGuest.bind(this);
    this.handleGuestNameConfirm = this.handleGuestNameConfirm.bind(this);
    this.selectAttendance = this.selectAttendance.bind(this);
    this.submitRsvps = this.submitRsvps.bind(this);
    this.name = this.props.name;
    this.rsvpDisabled = false;
    this.handleSubmit = async event => {
      event.preventDefault();
      this.setState({awaiting: true});
      try {
        const result = await this.get_guests(this.password, this.state.lastName);
        const members = result.data.members;
        const exactMatches = members.filter((guest) => guest.name === this.state.firstName + " " + this.state.lastName);
        if (exactMatches.length === 1) {
            const attendances = {};
            const groupResult = await this.get_group(this.password, exactMatches[0].name);
            let i;
            for (i = 0; i < groupResult.data.members.length; i++) {
              attendances[groupResult.data.members[i].name] = {Ceremony: false, Sangeet: false};
            }
            this.setState({group: groupResult.data.members, attendance: attendances});
        } else if (members.length === 0) {
          this.setState( {
            noMatchFound: true,
            awaiting: false
          } );
        } else {
          if (nicknames()[this.state.firstName]) {
            const nicknameMatches = result.data.members.filter((member) => nicknames()[this.state.firstName].includes(member.name.split(" ")[0]));
            if (nicknameMatches.length > 0) {
              this.setState({
                  guestList: nicknameMatches,
                  awaiting: false
              });
            } else {
              this.setState({
                  guestList: result.data.members,
                  awaiting: false
              });
            }
          } else {
            this.setState({
                guestList: result.data.members,
                awaiting: false
            });
          }
        }
        console.log("Guest list: " + this.state.guestList);
      } catch (err) {
        console.log(err);
      }
    };
  }
  
  async handleGuestNameConfirm(event) {
    this.setState({awaiting: true});
    const result = await this.get_group(this.password, this.state.highlightedGuest);
    const attendances = {};
    result.data.members.forEach((member) => {
      console.log("member name" + member.name);
      attendances[member.name] = {Ceremony: false, Sangeet: false};
    });
    
    this.setState({group: result.data.members, attendance: attendances});
    return result;
  }
  
  handlePlusOneNameChange(event) {
    this.setState({
      plusOneName: event.target.value,
    });
  }
  
  handleFirstNameChange(event) {
    if (event.target.value.length > 0) {
      this.setState({
        firstName: event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
      });
    } else {
      this.setState({
        firstName: event.target.value,
      });
    }
  }
    
  handleLastNameChange(event) { 
    if (event.target.value.length > 0) {
      this.setState({
        lastName: event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
      });
    } else {
      this.setState({
        lastName: event.target.value,
      });
    }
  }
  
  selectAttendance(name, event, respondent) {
    var currAttendance = {};
    if (name in this.state.attendance) {
      currAttendance = this.state.attendance[name];
    }
    if (currAttendance[event]) {
      currAttendance[event] = false;
    } else {
      currAttendance[event] = true;
    }
    currAttendance.respondent = respondent;
    
    // Need to copy attendance here instead of setting it to attendance of single person.
    this.setState(prevState => ({
      attendance: {
        ...prevState.attendance,
        [name]: currAttendance
      }
    }));

  }
  
  highlightGuest(name) {
      this.setState({highlightedGuest: name});
  }
  
  async submitRsvps() {
    const promises = Object.entries(this.state.attendance).map((person) => this.put_rsvp(this.password, this.getAttendanceName(person), person[1].Sangeet, person[1].Ceremony, person[1].respondent));
    await Promise.all(promises);
    this.setState({done: true})
  }
  
  // Method to return the person's name, or if it is the record for the plus-one, to return the plus-one name.
  getAttendanceName(person) {
    return person[0] === "PlusOne" ? this.state.plusOneName : person[0]
  }
  
  put_rsvp(password, name, sangeet, ceremony, respondent) {
      var pathParams = {};
      var apigClientFactory = require("aws-api-gateway-client").default;
      var config = {
        invokeUrl: "https://d6czey07q1.execute-api.us-west-2.amazonaws.com/test/rsvp?name=" + name + "&sangeet=" + sangeet + "&ceremony=" + ceremony + "&respondent=" + respondent
      };
      var apigClient = apigClientFactory.newClient(config);
      var pathTemplate = "";
      var method = "PUT";
      var body = {};
      var additionalParams = {
        headers: {
          Authorization: btoa(password)
        }
      };
      var result = apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      return result;
  }
  
  get_group(password, name) {
      var pathParams = {};
      var apigClientFactory = require("aws-api-gateway-client").default;
      var config = {
        invokeUrl: "https://d6czey07q1.execute-api.us-west-2.amazonaws.com/test/group?name=" + name
      };
      var apigClient = apigClientFactory.newClient(config);
      var pathTemplate = "";
      var method = "GET";
      var body = {};
      var additionalParams = {
        headers: {
          Authorization: btoa(password)
        }
      };
      var result = apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      return result;
  }
  
  get_guests(password, name) {
      var pathParams = {};
      var apigClientFactory = require("aws-api-gateway-client").default;
      var config = {
        invokeUrl: "https://d6czey07q1.execute-api.us-west-2.amazonaws.com/test/guest?name=" + name
      };
      var apigClient = apigClientFactory.newClient(config);
      var pathTemplate = "";
      var method = "GET";
      var body = {};
      var additionalParams = {
        headers: {
          Authorization: btoa(password)
        }
      };
      var result = apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      return result;
  }
    
  render() {
    const buttonName = this.state.awaiting ? "Submitting" : "Submit";
    var contents;
    if (this.state.done) {
      contents = (<div style={{fontSize: "1.2em" }}>Thanks for responding! If you're still looking for accommodations, check out <Link to="/location" style={{ textDecoration: "underline" }}>this page!</Link></div>)
    } else if (this.state.group.length !== 0) {
      contents = ( <GroupResponder respondentFirstName={this.state.firstName} respondentLastName={this.state.lastName} plusOneName={this.state.plusOneName} handlePlusOneNameChange={this.handlePlusOneNameChange} submitRsvps={this.submitRsvps} attendance={this.state.attendance} selectAttendance={this.selectAttendance} group={this.state.group} handleGuestNameConfirm={this.handleGuestNameConfirm} guestList={this.state.guestList} highlightGuest={this.highlightGuest} highlighted={this.state.highlightedGuest} /> )
    }
    else if (this.state.guestList.length === 0) {
      contents = ( <NameForm noMatchFound={this.state.noMatchFound} firstName={this.state.firstName} lastName={ this.state.lastName } awaiting={ this.props.awaiting } handleFirstNameChange={ this.handleFirstNameChange } handleLastNameChange={ this.handleLastNameChange } handleSubmit={ this.handleSubmit } buttonName={ buttonName } /> );
    }
    else {
      contents = ( <GuestSelector handleGuestNameConfirm={this.handleGuestNameConfirm} guestList={this.state.guestList} highlightGuest={this.highlightGuest} highlighted={this.state.highlightedGuest} /> );
    }
    if (this.rsvpDisabled) {
      contents = (<div style={{ fontSize: "1.2em" }}>Coming soon!</div>)
    }
    return (
            <div>
              <h2 className="w3-center">RSVP</h2>
              <Flexbox minWidth="100vw" className="w3-center" style={{ justifyContent: "center" }}>
                {contents}
              </Flexbox>
            </div>
           );
  }  
}

class GuestSelector extends React.Component {
    
    constructor(props) {
      super(props);
    }
    
    render() {
       const matchingGuests = this.props.guestList.map((guest) => <MatchingGuest key={guest.name} highlighted={this.props.highlighted === guest.name} highlightGuest={this.props.highlightGuest} name={guest.name} />);
       const disabled = (this.props.highlighted === undefined);
       const title = disabled ? "Please select a name above" : undefined;
       return (        
        <Flexbox justifyContent="center" flexWrap="wrap" style={{width: "33%", minWidth: "280px" }} className="w3-center">
            These are the closest matches we found to your name. Select your name below if there is a match.
            { matchingGuests }
            <Button title={title} disabled={disabled} onClick={this.props.handleGuestNameConfirm}>Confirm</Button>
        </Flexbox>
        );
    }

}

class NameForm extends React.Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        const buttonName = this.props.awaiting ? "Submitting" : "Submit";
        return (
          <div className="w3-center">
            <div className="form-container" style={{ width: "50vw", minWidth: "280px" }}>
              <Form onSubmit={this.props.handleSubmit}>
                <Form.Group controlId="formFirstName" class="form-group">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    value={this.props.firstName}
                    onChange={this.props.handleFirstNameChange}
                    placeholder="First Name"
                  />
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    value={this.props.lastName}
                    onChange={this.props.handleLastNameChange}
                    placeholder="Last Name"
                  />
    
                </Form.Group>
                <Button
                  disabled={this.props.awaiting || this.props.firstName.length === 0 || this.props.lastName.length === 0}
                  type="submit"
                  style={{ top: "1em" }}
                >
                  {buttonName}
                </Button>
               <div style={{ color: "red" }} className={this.props.noMatchFound ? "w3-show" : "w3-hide"}>We weren't able to find a guest that matched your name, try again. If it doesn't work, e-mail us at riyandan2020@gmail.com and let us know whether you'll be attending!</div>
              </Form>
            </div>
          </div>
        );
    }
}

class MatchingGuest extends React.Component {
      constructor(props) {
        super(props)
      }
      
      render() {
        return (
        <Flexbox flexGrow={1} justifyContent="center" style={ {width: "100%", textAlign: "center" } } className={"selectable-guest" + (this.props.highlighted ? " highlighted" : "")} onClick={() => this.props.highlightGuest(this.props.name) }>
            { this.props.name }
        </Flexbox>
        );
      }
}

class GroupMember extends React.Component {
  
  constructor(props) {
    super(props)
    this.sangeetSelectionClass = "";
    this.ceremonySelectionClass = "";
  }
      
  render() {
        const name = this.props.isPlusOne ? this.props.plusOneName : this.props.guest.name;
        const attendanceKey = this.props.isPlusOne ? "PlusOne" : this.props.guest.name
    
        console.log("Attendance key: " + attendanceKey);
        if (this.props.attendance[attendanceKey]) {
          this.sangeetSelectionClass = this.props.attendance[attendanceKey].Sangeet ? " highlighted" : "";
          this.ceremonySelectionClass = this.props.attendance[attendanceKey].Ceremony ? " highlighted" : "";
        }
        
        const nameClass = this.props.isPlusOne ? "w3-hide" : "w3-show";
        const formClass = this.props.isPlusOne ? "w3-show" : "w3-hide";

        return (
        <div style={{ width: "100%", padding: "10px" }}>
          <b className={nameClass}>{name}</b>
          <div className={formClass}>
            <b>Plus One: </b>
            <input
            type="text"
            value={this.props.plusOneName}
            onChange={this.props.handlePlusOneNameChange}
            />
          </div>
          <Flexbox flexWrap="wrap" style={{ width: "100%" }}>
            <Flexbox justifyContent="center" flexGrow={1} style={{fontSize: "1.3em", width: "50%", padding: "2px"}} className={"w3-center selectable-guest" + this.sangeetSelectionClass} onClick={(event) => this.props.selectAttendance(attendanceKey, "Sangeet", this.props.respondent)}>
              Sangeet
            </Flexbox>
            <Flexbox justifyContent="center" flexGrow={1} style={{fontSize: "1.3em", width: "50%", padding: "2px"}} className={"w3-center selectable-guest" + this.ceremonySelectionClass} onClick={(event) => this.props.selectAttendance(attendanceKey, "Ceremony", this.props.respondent)}>
              Ceremony
            </Flexbox>
          </Flexbox>
        </div>
        );

  }
}

class PlusOneQuestion extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <Flexbox flexWrap="wrap" justifyContent="center" style={{width: "100%"}} className={this.props.shouldAskForPlusOne ? "w3-show" : "w3-hide"}>
        <Flexbox justifyContent="center" style={{width: "100%"}} >We've reserved an extra seat for you, do you plan to bring a plus-one?</Flexbox>
        <Flexbox onClick={this.props.handlePlusOneYes} className="selectable-guest" style={{width: "50%"}}>Yes</Flexbox>
        <Flexbox onClick={this.props.handlePlusOneNo} className="selectable-guest" style={{width: "50%"}}>No</Flexbox>
      </Flexbox>
    )
  }
}

class GroupResponder extends React.Component {
    constructor(props) {
      super(props);
      this.handlePlusOneYes = this.handlePlusOneYes.bind(this);
      this.handlePlusOneNo = this.handlePlusOneNo.bind(this);
      this.state = {
        plusOneAnswered: false,
        plusOneAnsweredYes: false
      };
    }
    
    handlePlusOneYes() {
        this.setState({
          plusOneAnswered: true,
          plusOneAnsweredYes: true
        });
    }
    
    handlePlusOneNo() {
        this.setState({
          plusOneAnswered: true  
        });
    }
    
    render() {
       const matchingGuests = this.props.group.map((guest) => <GroupMember respondent={this.props.respondentFirstName + " " + this.props.respondentLastName} plusOneName={this.props.plusOneName} handlePlusOneNameChange={this.props.handlePlusOneNameChange} key={guest.name} attendance={this.props.attendance} selectAttendance={this.props.selectAttendance} highlighted={this.props.highlighted} guest={guest} highlightGuest={this.props.highlightGuest}/>);
       const shouldAskForPlusOne = (matchingGuests.length === 1) && !this.state.plusOneAnswered;
       const shouldIncludePlusOne = (matchingGuests.length === 1) && this.state.plusOneAnsweredYes;
       const plusOneGuest = <GroupMember respondent={this.props.respondentFirstName + " " + this.props.respondentLastName} plusOneName={this.props.plusOneName} handlePlusOneNameChange={this.props.handlePlusOneNameChange} key="plusOne" attendance={this.props.attendance} selectAttendance={this.props.selectAttendance} highlighted={this.props.highlighted} isPlusOne={true} highlightGuest={this.props.highlightGuest}/>;
       if (shouldIncludePlusOne) {
         matchingGuests.push(plusOneGuest);
       }
       return (
          <div style={{textAlign: "center", width: "100%"}}>
          
          <div><span style={{fontWeight: "bold"}}>Sangeet + Mehendi: </span>Join our Sangeet (music and dancing) and Mehendi (henna) at 11:30 AM on Oct 3, 2021 at the <a target="_blank" href="https://goo.gl/maps/gjZMyHPAYHANQirx6">Dhamankar household</a>.</div>
          <div><span style={{fontWeight: "bold"}}>Ceremony + Reception: </span>Join us for our wedding day! The ceremony starts at 2:30 PM on Oct 4, 2021 at <a target="_blank" href="https://g.page/penrynestate?share">Pen Ryn Estate</a>.</div>
          <hr/>
          <div>For each member in your party, select which events they will be attending and click <b>Confirm</b>.</div>
          <div>If you (or your party) cannot attend any events, leave all boxes unselected (pink) and click <b>Confirm</b>.</div>
            <Flexbox flexWrap="wrap" justifyContent="center" style={{ minWidth: "300px", width: "40%", margin: "auto"}}>
                { matchingGuests }
            <PlusOneQuestion handlePlusOneYes={this.handlePlusOneYes} handlePlusOneNo={this.handlePlusOneNo} shouldAskForPlusOne={shouldAskForPlusOne}/>
            </Flexbox >
            <Button disabled={shouldAskForPlusOne} onClick={this.props.submitRsvps}>Confirm</Button>
          </div>
        );
    }    
}