import React from "react";
import { Button, Form } from "react-bootstrap";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.allow = this.props.allow;
    this.handleChange = this.props.handleChange;
    this.handleSubmit = this.props.handleSubmit;
    this.password = this.props.password;
  }

  validateForm() {
    return this.state.password.length > 0;
  }

  render() {
    const buttonName = this.props.awaiting ? "Submitting" : "Submit";
    const handleChange = this.props.awaiting
      ? event => {}
      : this.props.handleChange;
    return (
      <div>
        <h2 className="w3-center">Password</h2>
        <div className="form-container">
          <Form onSubmit={this.props.handleSubmit}>
            <Form.Group controlId="formBasicPassword" class="form-group">
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={this.props.password}
                onChange={handleChange}
                placeholder="Password"
              />
            </Form.Group>
            <Button
              disabled={this.props.awaiting}
              type="submit"
              style={{ top: "1em" }}
            >
              {buttonName}
            </Button>
            {this.props.isDenied ? (
              <span style={{ fontColor: "Red", color: "red" }}>
                {" "}
                Incorrect password.
              </span>
            ) : (
              <span />
            )}
          </Form>
        </div>
      </div>
    );
  }
}
