import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Link, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { spring, AnimatedRoute } from "react-router-transition";
import { AnimatedSwitch } from "react-router-transition";

import "./index.css";

import engagement from "./images/Engagement.jpg";
import engagement2 from "./images/Engagement2.jpg";
import engagement3 from "./images/Engagement3.jpg"; // Copy of engagement1. Hack to get around my logic being bad.
import engagement4 from "./images/Engagement4.jpg"; // Copy of engagement2. Hack to get around my logic being bad.
import grad from "./images/proposal.jpg";
import penRynLarge from "./images/pen-ryn-large.jpg";

import NavBarButton from "./NavBarButton.js";
import FAQ from "./FAQ.js";
import Gifts from "./Gifts.js"
import RSVP from "./RSVP.js";
import Login from "./Login.js";
import Accomodations from "./Accomodations.js";
import { authorize, authorizeWithToken } from "./Auth.js";
import cookie from "react-cookies";
import RenderInBrowser from "react-render-in-browser";

class BannerImage extends React.Component {
  constructor(props) {
    super(props);
    this.imageMap = {
      "/": engagement,
      "/faq": engagement2,
      "/gifts": engagement4,
      "/location": penRynLarge,
      "/rsvp": engagement3
    };
  }

  render() {
    const activeImage = this.imageMap[this.props.currentPath];
    //const inactiveImages = Object.values(this.imageMap)
    //  .filter((image) => image != activeImage)
    //  .map((preloadImage) => "url(" + preloadImage + ")" );
    //const orderedImages = inactiveImages;
    //orderedImages.push("url(" + activeImage + ")");
    const bgImages = Object.values(this.imageMap).map((image, index) => {
      const opacity = activeImage === image ? 0.7 : 0;
      const height = activeImage === image ? "40em" : "0em";
      const style = {
        height: height,
        position: "relative",
        opacity: opacity,
        backgroundImage: "url(" + image + ")"
      };
      return (
        <div className={"backgroundimage"} style={style} key={index}>
          <span
            className="w3-center w3-padding-large w3-wide w3-animate-opacity hide-small"
            style={{
              fontSize: "2em",
              backgroundColor: "#f7cde0",
              color: "#000000",
              position: "absolute",
              top: "70%",
              right: "5%"
            }}
          >
            OCT 4, 2021
          </span>
          <span
            className="w3-center w3-padding-large w3-wide w3-animate-opacity hide-small"
            style={{
              fontSize: "2em",
              backgroundColor: "#f7cde0",
              color: "#000000",
              position: "absolute",
              top: "70%",
              left: "5%"
            }}
          >
            RIYA & DAN
          </span>
          <span
            className="w3-center w3-padding-large w3-wide w3-animate-opacity hide-large"
            style={{
              fontSize: "1.2em",
              backgroundColor: "#f7cde0",
              color: "#000000",
              position: "absolute",
              top: "70%",
              right: "5%"
            }}
          >
            OCT 4
          </span>
          <span
            className="w3-center w3-padding-large w3-wide w3-animate-opacity hide-large"
            style={{
              fontSize: "1.2em",
              backgroundColor: "#f7cde0",
              color: "#000000",
              position: "absolute",
              top: "70%",
              left: "5%"
            }}
          >
            R + D
          </span>
        </div>
      );
    });
    return <div style={{ position: "relative" }}>{bgImages}</div>;
  }
}

class NavBar extends React.Component {
  state = {
    navClasses: "w3-bar black-on-small",
    isMenuHidden: true
  };

  constructor(props) {
    super(props);
    this.showMenu = this.showMenu.bind(this);
  }

  showMenu(event) {
    this.setState({ isMenuHidden: !this.state.isMenuHidden });
  }

  render() {
    const paths = [
      {
        pathName: "/",
        icon: "fa fa-heart",
        title: "Our Story"
      },
      {
        pathName: "/faq",
        icon: "fa fa-info-circle",
        title: "FAQ"
      },
      {
        pathName: "/location",
        icon: "fa fa-compass",
        title: "Accommodations"
      },
      {
        pathName: "/rsvp",
        icon: "fa fa-envelope",
        title: "RSVP"
      },
      {
        pathName: '/gifts',
        icon: "fa fa-gift",
        title: "Gifts"
      }
    ];
    return (
      <div class="navBar">
        <div class="w3-top">
          <div className={this.state.navClasses}>
            <a
              style={{ fontSize: "1.4em" }}
              class="theme-color w3-bar-item w3-button w3-hover-black hide-large"
              href="javascript:void(0);"
              onClick={this.showMenu}
              title="Toggle Navigation Menu"
            >
              <i class="fa fa-bars"></i>
            </a>
            {paths.map((path, index) => (
              <Link
                key={index}
                to={path.pathName}
                className={
                  "navbar hide-small" +
                  (path.pathName === this.props.currentPath
                    ? " themed-background"
                    : "")
                }
              >
                <i class={path.icon} /> {path.title}
              </Link>
            ))}
          </div>
          <div
            id="navDemo"
            class={
              this.state.isMenuHidden
                ? "mobile-menu hide hide-large"
                : "mobile-menu w3-bar-block w3-white hide-large"
            }
          >
            <Link
              onClick={this.showMenu}
              to="/"
              className={
                this.state.isMenuHidden
                  ? "navbar mobile-menu-item hide"
                  : "navbar mobile-menu-item"
              }
            >
              Our Story
            </Link>
            <Link
              onClick={this.showMenu}
              to="/faq"
              className={
                this.state.isMenuHidden
                  ? "navbar mobile-menu-item hide"
                  : "navbar mobile-menu-item"
              }
            >
              FAQ
            </Link>
            <Link
              onClick={this.showMenu}
              to="/location"
              className={
                this.state.isMenuHidden
                  ? "navbar mobile-menu-item hide"
                  : "navbar mobile-menu-item"
              }
            >
              Accommodations
            </Link>
            <Link
              onClick={this.showMenu}
              to="/rsvp"
              className={
                this.state.isMenuHidden
                  ? "navbar mobile-menu-item hide"
                  : "navbar mobile-menu-item"
              }
            >
              RSVP
            </Link>
            <Link
              onClick={this.showMenu}
              to="/gifts"
              className={
                this.state.isMenuHidden
                  ? "navbar mobile-menu-item hide"
                  : "navbar mobile-menu-item"
              }
            >
              Gifts
            </Link>

          </div>
        </div>
      </div>
    );
  }
}

class AboutUs extends React.Component {
  render() {
    return (
      <div>
        <h3 class="w3-center">Our Story</h3>
        <hr />
        <Proposal />
        <hr />
      </div>
    );
  }
}

class Proposal extends React.Component {
  render() {
    return (
      <div>
        <div
          className="proposal single-column-on-small"
          style={{ display: "grid", gridTemplateColumns: "50vw 50vw" }}
        >
          <div
            className="full-width-on-small"
            style={{
              paddingLeft: "14vw",
              paddingRight: "3vw",
              fontSize: "18px",
              width: "50vw",
              columnSpan: 1
            }}
          >
            <h4 class="w3-center">
              <b>HOW WE MET</b>
            </h4>
            Dan and Riya met on a snow day at the University of Maryland. There
            was supposed to be a snowball fight and general snow shenanigans on
            campus so they both went with their mutual friend. Riya hit Dan with
            a snowball from really far away and impressed Dan with her excellent
            aim. Later, at a UMD party, she guessed his middle name on the first
            try. That’s when Dan knew he had to ask her out.
          </div>
          <hr className="hide-large" />
          <div
            className="full-width-on-small"
            style={{
              paddingLeft: "3vw",
              paddingRight: "14vw",
              fontSize: "18px",
              width: "50vw",
              columnSpan: 1
            }}
          >
            <h4 class="w3-center">
              <b>THE PROPOSAL</b>
            </h4>
            Dan proposed to Riya on her birthday in Philadelphia. After a
            delicious birthday lunch at The Dandelion downtown, they walked to
            Love Park. Some unsuspecting strangers took their picture as Dan
            nervously proposed. When Riya said yes, they took a jubilant walk to
            the Philadelphia Museum of Art and the rest is history!
          </div>
        </div>
        <hr />
        <h4 class="w3-center">
          <b>THE WEDDING</b>
        </h4>
        <div
          className="w3-center"
          style={{
            fontSize: "18px",
            paddingLeft: "14vw",
            paddingRight: "14vw",
            paddingBottom: "3vh"
          }}
        >
          The Sangeet will be held at the <a href="https://goo.gl/maps/L5qqoHpLgqehZ5n89" target="_blank">
          Dhamankar household</a> on Sunday October 3, 2021.
          The ceremony and reception will be held at the Pen Ryn Mansion in
          Bensalem, PA on Monday October 4, 2021. The address is 1601 State Rd,
          Bensalem, PA 19010. The ceremony will start at 2:30 PM and the reception will follow. 
        </div>
        <div
          className="backgroundimage"
          style={{
            backgroundPosition: "center 20vh",
            width: "100vw",
            backgroundImage: "url(" + grad + ")"
          }}
        />
      </div>
    );
  }
}

function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22
  });
}

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`
  };
}

class MainContent extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const location= this.props.location;
    console.log(location);
    const bounceTransition = {
      // start in a transparent, upscaled state
      atEnter: {
        opacity: 0
      },
      // leave in a transparent, downscaled state
      atLeave: {
        opacity: bounce(0)
      },
      // and rest at an opaque, normally-scaled state
      atActive: {
        opacity: bounce(1)
      }
    };
    return (
      <div className="main-content">
        <BannerImage currentPath={location.pathname} />
        <NavBar currentPath={location.pathname} />
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="route-wrapper"
        >
          <Route exact path="/" component={AboutUs} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/gifts" component={Gifts} />
          <Route
            exact
            path="/rsvp"
            render={routeProps => (
              <RSVP {...routeProps} password={this.props.password} />
            )}
          />
          <Route
            exact
            path="/location"
            render={routeProps => (
              <Accomodations {...routeProps} apiKey={this.props.apiKey} />
            )}
          />
        </AnimatedSwitch>
      </div>
    );
  }
}

class WeddingHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      awaiting: false,
      password: "",
      hasAttempted: false
    };
    this.handleSubmit = async event => {
      event.preventDefault();
      this.setState({
        authenticated: this.state.authenticated,
        awaiting: true,
        password: this.state.password,
        hasAttempted: false
      });
      try {
        const result = await authorize(this.state.password);
        this.onAllowed(result);
      } catch (err) {
        this.onDenied();
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.onAllowed = this.onAllowed.bind(this);
    this.onDenied = this.onDenied.bind(this);
  }

  onAllowed(result) {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 2);
    if (this.authtoken == null) {
      cookie.save("authtoken", btoa(this.state.password), {
        path: "/",
        expires: expires
      });
    }
    this.setState({
      apiKey: result.data["apiKey"],
      authenticated: true,
      awaiting: false,
      password: this.state.password,
      hasAttempted: false
    });
  }

  onDenied() {
    cookie.remove("authtoken");
    this.setState({
      isDenied: true,
      authenticated: false,
      awaiting: false,
      password: this.state.password,
      hasAttempted: true
    });
  }

  handleChange(event) {
    this.setState({
      password: event.target.value,
      awaiting: false,
      authenticated: false
    });
  }

  render() {
    if (this.state.authenticated) {
      const MainContentWithRouter = withRouter(MainContent);
      return <MainContentWithRouter apiKey={this.state.apiKey} password={this.state.password} />;
    } else {
      if (!this.state.awaiting) {
        this.authtoken = cookie.load("authtoken");
        const awaiting = this.authtoken != null ? true : this.state.awaiting;
        if (this.authtoken != null) {
          this.state.password = atob(this.authtoken);
          authorizeWithToken(this.authtoken)
            .then(this.onAllowed)
            .catch(this.onDenied);
        }
      }
      return (
        <div>
          <RenderInBrowser ie only>
            <div className="w3-center">
              Sorry! The website doesn't look so good in internet explorer.
              Please check it out in Chrome or Firefox if possible!
            </div>
          </RenderInBrowser>
          <Login
            isDenied={this.state.isDenied}
            handleSubmit={this.handleSubmit}
            awaiting={this.state.awaiting}
            handleChange={this.handleChange}
            password={this.state.password}
          />
        </div>
      );
    }
  }
}
// ========================================

ReactDOM.render(
  <Router>
    <WeddingHome />
  </Router>,
  document.getElementById("root")
);
