
export default function nicknames() {
    return {
        David: ["Dave"],
        Katherine: ["Kathy"],
        Michael: ["Mike"],
        Benjamin: ["Ben"],
        Gwendolyn: ["Gwen"],
        Valerie: ["Val"],
        Geoff: ["Geoffrey"],
        Daniel: ["Dan", "Danny"],
        Dan: ["Daniel", "Danny"],
        Robert: ["Rob", "Robbie"],
        Rob: ["Robbie", "Robert"],
        Jayaram: ["Jay"],
        Timothy: ["Tim"],
        Catherine: ["Cat"],
        Mathew: ["Matt"],
        Matthew: ["Matt"],
        Maggie: ["Margaret"],
        Joseph: ["Joe"]
    };
}