import styled from "styled-components";

const NavBarButton = styled.a`
    padding:1em; 2em;
    float:left;
    width:auto;
    border:none;
    display:inline-block;
    vertical-align:middle;
    overflow:hidden;
    text-decoration:none;
    text-align:center;
    cursor:pointer;
    white-space:nowrap;
    color: #f7cde0;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    first-child,
    .w3-dropdown-click:hover;
    &:hover {
        color:#000!important;
        background-color: #f7cde0;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
    }
`;

export default NavBarButton;
