import React from "react";
import PropTypes from "prop-types";
import Paragraph, { ParagraphWithImage } from "./Paragraph.js";
import GoogleMap from "google-map-react";
import penryn from "./images/pen-ryn.png";
import radisson from "./images/radisson.jpg";
import magicGardens from "./images/magic_gardens.png";
import mutterMuseum from "./images/mutter_museum.jpg";
import libertyBell from "./images/LibertyBell.jpg";
import rockySteps from "./images/rocky_steps.jpg";
import sshp from "./images/sshp.jpg";
import holidayInn from "./images/holiday_inn.jpg";
import engagement from "./images/Engagement.jpg";

class Marker {
  constructor(markerType, markerPosition) {
    this.markerType = markerType;
    this.markerPosition = markerPosition;
  }
}

class MarkerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.markers = props.markers;
    this.markerType = props.markerType;
    this.iconClass = props.iconClass;
    this.handleClick = props.handleClick;
    this.setHighlightedMarker = props.setHighlightedMarker;
    this.markerListContents = this.markerListContents.bind(this);
  }

  render() {
    const contents = this.allMarkerContents(this.markers, this.markerType);
    const color = window.innerWidth < 768 ? "black" : this.props.color;
    return (
      <div>
        <div
          onMouseEnter={this.handleClick}
          onMouseLeave={this.handleClick}
          style={{ color: color, fontSize: "1.7em", fontFamily: "Cambria" }}
        >
          <i class={this.iconClass}></i> {this.markerType}
        </div>
        {contents}
        <hr />
      </div>
    );
  }

  allMarkerContents(markers, markerType) {
    return (
      <ul>
        {markers
          .filter(function(marker) {
            return marker.markerType === markerType;
          })
          .map(this.markerListContents)}
      </ul>
    );
  }

  markerListContents(marker) {
    const setHighlightedMarker = this.setHighlightedMarker;
    const onHighlight = () => {
      setHighlightedMarker(marker);
    };
    const markerHighlighted =
      this.props.highlightedMarker != null &&
      this.props.highlightedMarker.markerName === marker.markerName;
    if (marker.bookingCode != null) {
      return (
        <WithBooking
          key={marker.markerName}
          onHighlight={onHighlight}
          markerHighlighted={markerHighlighted}
          marker={marker}
        />
      );
    } else if (marker.description != null) {
      return (
        <WithDescription
          key={marker.markerName}
          onHighlight={onHighlight}
          markerHighlighted={markerHighlighted}
          marker={marker}
        />
      );
    } else
      return (
        <WithoutBooking
          key={marker.markerName}
          onHighlight={onHighlight}
          markerHighlighted={markerHighlighted}
          marker={marker}
        />
      );
  }
}

function WithoutBooking(props) {
  const color =
    window.innerWidth < 768
      ? "black"
      : props.markerHighlighted
      ? "#f7cde0"
      : "black";
  const textDecoration = window.innerWidth < 768 ? "none" : "underline";
  const cursor = window.innerWidth < 768 ? "auto" : "pointer";
  return (
    <li style={{ fontSize: "1.5em" }}>
      <a
        target="_blank"
        href={props.marker.otherUrl}
        onMouseLeave={props.onHighlight}
        onMouseEnter={props.onHighlight}
        style={{ textDecoration: textDecoration, cursor: cursor, color: color }}
      >
        {props.marker.markerName}
      </a>
    </li>
  );
}

function WithBooking(props) {
  const color =
    window.innerWidth < 768
      ? "black"
      : props.markerHighlighted
      ? "#f7cde0"
      : "black";
  const textDecoration = window.innerWidth < 768 ? "none" : "underline";
  const cursor = window.innerWidth < 768 ? "auto" : "pointer";

  return (
    <div>
      <li style={{ fontSize: "1.5em" }}>
        <a
          target="_blank"
          href={props.marker.bookingUrl}
          onMouseLeave={props.onHighlight}
          onMouseEnter={props.onHighlight}
          style={{
            textDecoration: textDecoration,
            cursor: cursor,
            color: color
          }}
        >
          {props.marker.markerName}
        </a>
      </li>
      <div>
        <div style={{ fontSize: "1.0em" }}>
          To book, click{" "}
          <a target="_blank" href={props.marker.bookingUrl}>
            here
          </a> by {props.marker.bookBy}{" "}
          and use code: <b>{props.marker.bookingCode}</b>
        </div>
        <div style={{ fontSize: "1.0em" }}>
          Or, call <b>{props.marker.phoneNumber}</b> and mention{" "}
          <b>The Dhamankar Wedding</b>
        </div>
      
      </div>
    </div>
  );
// REPLACE "Booking instructions coming soon" with this eventually <div>
//        <div style={{ fontSize: "1.0em" }}>
//          To book, click{" "}
//          <a target="_blank" href={props.marker.bookingUrl}>
//            here
//          </a>{" "}
//          and use code: <b>{props.marker.bookingCode}</b>
//        </div>
//        <div style={{ fontSize: "1.0em" }}>
//          Or, call <b>{props.marker.phoneNumber}</b> and mention{" "}
//          <b>The Dhamankar Wedding</b>
//        </div>
//      </div>
//    
}

function WithDescription(props) {
  const color =
    window.innerWidth < 768
      ? "black"
      : props.markerHighlighted
      ? "#f7cde0"
      : "black";
  const textDecoration = window.innerWidth < 768 ? "none" : "underline";
  const cursor = window.innerWidth < 768 ? "auto" : "pointer";

  return (
    <div>
      <li style={{ fontSize: "1.5em" }}>
        <a
          target="_blank"
          href={props.marker.otherUrl}
          onMouseLeave={props.onHighlight}
          onMouseEnter={props.onHighlight}
          style={{
            textDecoration: textDecoration,
            cursor: cursor,
            color: color
          }}
        >
          {props.marker.markerName}
        </a>
      </li>
      <div>
        <div style={{ fontSize: "1.0em" }}>{props.marker.description}</div>
      </div>
    </div>
  );
}

export default class Accomodations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { highlightedType: "None" };
    this.markers = [
      {
        markerName: "Pen Ryn Estate",
        markerType: "Venue",
        url: "",
        markerPosition: { lat: 40.0663428, lng: -74.9576016 },
        imageUrl: penryn,
        description:
          "Join us on the Delaware river at the lovely Pen Ryn Mansion on October 4, 2021",
        otherUrl: "https://www.penrynestate.com/"
      },
      {
        markerName: "Dhamankar Household",
        markerType: "Venue",
        otherUrl: "https://goo.gl/maps/L5qqoHpLgqehZ5n89",
        url: "",
        markerPosition: { lat: 40.05761, lng: -75.45560 },
        imageUrl: engagement,
        description: (<div>Join us for the Sangeet at <a href="https://goo.gl/maps/L5qqoHpLgqehZ5n89" target="_blank">884 Cloverly Rd, Berwyn, PA</a> on October 3, 2021</div>)
      },
      {
        markerName: "Holiday Inn Express",
        markerType: "Hotels",
        url:
          "http://icHotelssgroup.com/redirect?path=rates&brandCode=EX&regionCode=1&localeCode=en&GPC=DHW&HotelsCode=PNEEX&_PMID=99801505",
        markerPosition: { lat: 40.070737, lng: -74.964183 },
        imageUrl: holidayInn,
        bookingCode: "DHA",
        bookingUrl:
          "https://www.ihg.com/holidayinnexpress/hotels/us/en/bensalem/pneex/hoteldetail?qDest=1329%20Bristol%20Pike,%20Andalusia,%20PA%2019020,%20USA&qCiD=11&qCoD=12&qCiMy=82019&qCoMy=82019&qAdlt=1&qChld=0&qRms=1&qWch=0&qSmP=1&qIta=99801505&setPMCookies=true&qRtP=6CBARC&qGrpCd=DHA&qAkamaiCC=US&srb_u=1&qRad=30&qRdU=mi&presentationViewType=select&qSrt=sBR&qBrs=re.ic.in.vn.cp.vx.hi.ex.rs.cv.sb.cw.ma.ul.ki.va",
        phoneNumber: "215-245-5222",
        bookBy: "09/02/21"
      },
      {
        markerName: "Radisson Hotels",
        url: "http://Radisson.com/Trevosepa",
        markerType: "Hotels",
        markerPosition: { lat: 40.122988, lng: -74.978136 },
        imageUrl: radisson,
        bookingCode: "1021DG",
        bookingUrl: "http://Radisson.com/Trevosepa",
        phoneNumber: "1-800-333-3333",
        bookBy: "09/02/21"
      },
      {
        markerName: "Mutter Museum",
        url: "???",
        markerType: "Attractions",
        markerPosition: { lat: 39.953349, lng: -75.176745 },
        imageUrl: mutterMuseum,
        description:
          "The Mutter Museum is a medical museum located in the Center City area.",
        otherUrl: "http://muttermuseum.org/"
      },
      {
        markerName: "Philadelphia's Magic Gardens",
        url: "???",
        markerType: "Attractions",
        markerPosition: { lat: 39.942737, lng: -75.159312 },
        imageUrl: magicGardens,
        description:
          "Philadelphia's Magic Gardens is a non-profit, folk art environment, and gallery space.",
        otherUrl: "https://www.phillymagicgardens.org/"
      },
      {
        markerName: "Spruce Street Harbor Park",
        url: "???",
        markerType: "Attractions",
        markerPosition: { lat: 39.944502, lng: -75.141407 },
        imageUrl: sshp,
        description:
          "The Spruce Street Harbor Park is an urban beach located in Penn's Landing.",
        otherUrl:
          "https://www.delawareriverwaterfront.com/places/spruce-street-harbor-park"
      },
      {
        markerName: "Philadelphia Museum of Art",
        url: "???",
        markerType: "Attractions",
        markerPosition: { lat: 39.965272, lng: -75.180418 },
        imageUrl: rockySteps,
        description: "Check out the Rocky steps at the Philly Museum of Art!",
        otherUrl: "https://philamuseum.org/"
      }
    ];
    this.toggleVenues = this.toggleVenues.bind(this);
    this.toggleHotelss = this.toggleHotelss.bind(this);
    this.toggleAttractions = this.toggleAttractions.bind(this);
    this.setHighlightedMarker = this.setHighlightedMarker.bind(this);
  }

  toggleVenues(event) {
    this.setState({
      highlightedType: this.state.highlightedType === "Venue" ? "None" : "Venue"
    });
  }

  toggleHotelss(event) {
    this.setState({
      highlightedType:
        this.state.highlightedType === "Hotels" ? "None" : "Hotels"
    });
  }

  toggleAttractions(event) {
    this.setState({
      highlightedType:
        this.state.highlightedType === "Attractions" ? "None" : "Attractions"
    });
  }

  setHighlightedMarker(marker) {
    if (
      this.state.highlightedMarker != null &&
      this.state.highlightedMarker.markerName === marker.markerName
    ) {
      this.setState({ highlightedMarker: null });
    } else {
      this.setState({ highlightedMarker: marker });
    }
  }

  render() {
    this._distanceToMouse = (markerPos, mousePos, markerProps) => {
      const x = markerPos.x + 5;
      // because of marker non symmetric,
      // we transform it central point to measure distance from marker circle center
      // you can change distance function to any other distance measure
      const y = markerPos.y + 5;

      // it's just a simple example, you can tweak distance function as you wish
      return Math.sqrt(
        (x - mousePos.x) * (x - mousePos.x) +
          (y - mousePos.y) * (y - mousePos.y)
      );
    };
    const highlightedType = this.state.highlightedType;
    return (
      <div
        className="single-column-on-small"
        style={{
          borderTop: "solid",
          borderWidth: "1px",
          display: "grid",
          height: "95vh",
          gridTemplateColumns: "35vw 95vh"
        }}
      >
        <div
          className="map-table-on-small"
          style={{
            marginLeft: "1vw",
            width: "35vw",
            columnSpan: 1,
            height: "95vh"
          }}
        >
          <MarkerInfo
            highlightedMarker={this.state.highlightedMarker}
            setHighlightedMarker={this.setHighlightedMarker}
            handleClick={this.toggleVenues}
            color={this.state.highlightedType === "Venue" ? "#f7cde0" : "black"}
            iconClass="fa fa-heart"
            markerType="Venue"
            markers={this.markers.filter(
              marker => marker.markerType === "Venue"
            )}
          />
          <MarkerInfo
            highlightedMarker={this.state.highlightedMarker}
            setHighlightedMarker={this.setHighlightedMarker}
            handleClick={this.toggleHotelss}
            color={
              this.state.highlightedType === "Hotels" ? "#f7cde0" : "black"
            }
            highlightedType={highlightedType}
            iconClass="fa fa-home"
            markerType="Hotels"
            markers={this.markers.filter(
              marker => marker.markerType === "Hotels"
            )}
          />
          <MarkerInfo
            highlightedMarker={this.state.highlightedMarker}
            setHighlightedMarker={this.setHighlightedMarker}
            handleClick={this.toggleAttractions}
            color={
              this.state.highlightedType === "Attractions" ? "#f7cde0" : "black"
            }
            highlightedType={highlightedType}
            iconClass="fa fa-circle"
            markerType="Attractions"
            markers={this.markers.filter(
              marker => marker.markerType === "Attractions"
            )}
          />
        </div>
        <div
          className="map-width-on-small"
          style={{ width: "65vw", columnSpan: 1, height: "125vh" }}
        >
          <GoogleMap
            apiKey={this.props.apiKey}
            center={{ lat: 40.0063428, lng: -74.9576016 }}
            defaultZoom={10.5}
            distanceToMouse={this._distanceToMouse}
          >
            {this.markers.map(marker =>
              renderMapMarker(
                marker,
                highlightedType,
                this.state.highlightedMarker
              )
            )}
          </GoogleMap>
        </div>
      </div>
    );
  }
}

function renderMapMarker(marker, highlightedType, highlightedMarker) {
  const highlighted = highlightedType === marker.markerType;
  return (
    <MapMarker
      key={marker.markerName}
      highlightedMarker={highlightedMarker}
      markerName={marker.markerName}
      image={marker.imageUrl}
      highlighted={highlighted}
      markerType={marker.markerType}
      lat={marker.markerPosition.lat}
      lng={marker.markerPosition.lng}
    ></MapMarker>
  );
}

class MapMarker extends React.Component {
  render() {
    return mapContents(
      this.props,
      this.props.image,
      this.props.highlightedMarker
    );
  }
}

function mapContents(props, image, highlightedMarker) {
  const isSingleHighlighted =
    highlightedMarker != null &&
    props.markerName === highlightedMarker.markerName;
  const iconOpacity = props.highlighted || isSingleHighlighted ? 1.0 : 0.3;
  const opacity = props.$hover || isSingleHighlighted ? 0.7 : 0.0;
  const style =
    props.$hover || isSingleHighlighted ? "map-marker hover" : "map-marker";
  const iconClasses = {
    Attractions: "fa fa-circle",
    Hotels: "fa fa-home",
    Venue: "fa fa-heart"
  };
  const color = props.markerType === "Venue" ? "black" : "black";
  return (
    <div key={props.markerName}>
      <div>
        <i
          class={iconClasses[props.markerType] + " map-marker " + style}
          style={{
            color: color,
            transition: "200ms",
            opacity: iconOpacity,
            fontSize: "1.85em"
          }}
        />
      </div>
      <div
        style={{
          zIndex: 0,
          opacity: opacity * 2,
          backgroundColor: "white",
          transition: "0.4s opacity",
          position: "relative",
          right: "140px",
          bottom: "232px",
          border: "1px solid",
          width: "309px",
          height: "208px"
        }}
      />
      <div
        style={{
          zIndex: 1,
          color: "black",
          textAlign: "center",
          backgroundSize: "cover",
          backgroundImage: "url(" + image + ")",
          opacity: opacity,
          backgroundColor: "#f7cde0",
          transition: "0.4s opacity",
          position: "relative",
          right: "140px",
          bottom: "440px",
          border: "1px solid",
          width: "309px",
          height: "208px"
        }}
      >
        <h4 style={{ fontWeight: "bold" }}>{props.markerName}</h4>
      </div>
    </div>
  );
}
