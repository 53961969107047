export function authorize(password) {
  var pathParams = {};
  var apigClientFactory = require("aws-api-gateway-client").default;
  var config = {
    invokeUrl: "https://d6czey07q1.execute-api.us-west-2.amazonaws.com/test"
  };
  var apigClient = apigClientFactory.newClient(config);
  var pathTemplate = "";
  var method = "GET";
  var body = {};
  var additionalParams = {
    headers: {
      Authorization: btoa(password)
    }
  };
  var result = apigClient.invokeApi(
    pathParams,
    pathTemplate,
    method,
    additionalParams,
    body
  );
  console.log("Submitted auth request " + result);
  return result;
}

export function authorizeWithToken(token) {
  var pathParams = {};
  var apigClientFactory = require("aws-api-gateway-client").default;
  var config = {
    invokeUrl: "https://d6czey07q1.execute-api.us-west-2.amazonaws.com/test"
  };
  var apigClient = apigClientFactory.newClient(config);
  var pathTemplate = "";
  var method = "GET";
  var body = {};
  var additionalParams = {
    headers: {
      Authorization: token
    }
  };
  var result = apigClient.invokeApi(
    pathParams,
    pathTemplate,
    method,
    additionalParams,
    body
  );
  console.log(result);
  return result;
}
