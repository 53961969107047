import React from "react";
import PropTypes from "prop-types";
import Paragraph, { ParagraphWithImage } from "./Paragraph.js";
import { Link } from "react-router-dom";

export default class FAQ extends React.Component {
  render() {
    return (
      <div>
        <h3 class="w3-center">FAQ</h3>
        <Question
          question="Should we bring a gift to the wedding?"
          answer={
            <span>
              We're not asking people to bring gifts as we know many
              of you are traveling from far away to join us for our special
              occasion. Celebrating with you at the wedding is enough of a gift
              for us! If you really want to give something, consider donating to
              one of our favorite charities:{" "}
              <a href="https://www.weareplannedparenthood.org">
                Planned Parenthood
              </a>{" "}
              or the{" "}
              <a href="https://www.nrdc.org/">
                Natural Resources Defense Council
              </a>
              . If you wish to buy us a gift directly, we created a gift registry
              {" "}<a href="https://www.zola.com/registry/riyandan">on Zola</a>.
            </span>
          }
        />
        <Question
          question="Are there hotels nearby?"
          answer={
            <span>
              Yes! We have booked a block of rooms in two hotels: Holiday Inn
              Express and Radisson Hotel. Both are within 15 minutes of the
              venue. To reserve a room, check out the{" "}
              <Link to="/location">accomodations page</Link>. We'll add new details for the group discount as soon as we know.
            </span>
          }
        />
        <Question
          question="What’s the best way to get to the venue?"
          answer="If you’re flying into PHL, driving from the airport takes about 30-45 minutes (traffic dependent). We encourage carpooling if any of you plan to get into town the same day! If you’re driving from anywhere else, the venue is located right off I-95 (Google Maps is your friend). Once on the premises, follow the beautiful tree-lined driveway and look for signs that say Pen Ryn Mansion. There is ample parking at the venue."
        />
        <Question
          question="I think I might get to Philadelphia early. What is there to do?"
          answer="You’re in luck because there is so much to do in Philly. If you’re a history buff, check out Independence Hall, the Liberty Bell, and the Constitution Center. If you want to see cool medical and anatomical anomalies, Riya’s favorite museum is the Mütter Museum (Dan thinks it's spooky). If you consider yourself artsy fartsy, Philadelphia’s Magic Gardens are waiting for you. Everyone should run up the Rocky steps at the Museum of Art because it’s required by law."
        />
        <Question
          question="What type of food will be served at the wedding?"
          answer="Indian food! There will be delicious appetizers for cocktail hour and a buffet for dinner. Everything will be vegetarian. Please let us know if you have any food allergies or other diet restrictions so we can work with our caterer to accommodate them."
        />
        <Question
          question="What should I wear to the ceremony?"
          answer="The dress code for our wedding is formal: dresses, blouses & skirts/dress slacks, saris, lehengas, suits & ties, and/or blazers. If you wear all of those at once, you win! The ceremony will be held outdoors (weather permitting) and then the party will continue on inside the mansion. Wear shoes for dancin'!"
        />
        <Question
          question="What is a sangeet?"
          answer="The sangeet kicks off wedding celebrations with singing, dancing, games, and hanging out with each other's families and friends. Traditionally, it was an event just for women, but nowadays everyone is invited. There will be a mehendi (henna) artist to draw designs on people's hands. Lunch and dinner will be served."
        />
        <Question
          question="What should I wear to the sangeet?"
          answer="The dress code for the sangeet is colorful and casual. Wear Indian attire if you want to!"
        />
        <Question
          question="When should I be there for the sangeet?"
          answer="The sangeet will start at 11:30 AM, but no worries if you need to get there later! Lunch will be served around 12:30 PM and dinner around 6:00 PM. If you plan to attend just a portion of the Sangeet, please let us know if you will miss either meal. Henna and dancing will take place between lunch and dinner."
        />
      </div>
    );
  }
}

class Question extends React.Component {
  static propTypes = {
    question: PropTypes.string.isRequired
  };

  render() {
    const { question, answer } = this.props;
    return (
      <div>
        <div className="question">Q: {question}</div>
        <div className="answer">A: {answer}</div>
        <hr />
      </div>
    );
  }
}
