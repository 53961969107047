import styled from "styled-components";
import React from "react";
import ReactDOM from "react-dom";

const Paragraph = styled.div`
  padding-top: 2vh !important;
  padding-bottom: 2vh !important;
  text-align: justify !important;
  font-size: 18px;
`;

export default Paragraph;
