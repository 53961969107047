import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class Gifts extends React.Component {
  render() {
    return (
      <div className="main-content answer">
        <h3 class="w3-center">Gifts</h3>
            <div>
              We're not asking people to bring gifts as we know many
              of you are traveling to join us for our special
              occasion. Celebrating with you at the wedding is enough of a gift
              for us! If you want to give something, consider donating to
              one of our favorite charities:{" "}
              <a href="https://www.weareplannedparenthood.org">
                Planned Parenthood
              </a>{" "}
              or the{" "}
              <a href="https://www.nrdc.org/">
                Natural Resources Defense Council
              </a>
              . If you wish to buy us a gift directly, we have created a gift registry
              {" "}<a href="https://www.zola.com/registry/riyandan">on Zola</a>.
            </div>
        </div>
        )
    }
}
